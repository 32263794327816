import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transform the State of the CustomerFilter based on the Type
 */
@Pipe({
  name: 'translateFilterCustomer'
})
export class TranslateFilterCustomerPipe implements PipeTransform {
/**
 * Transform the state based on the Type
 * @param value 
 * @param type 
 * @returns 
 */
  transform(value: string[], type: string): string[] {
    if (type == 'filterByCustomerType') {
      let returnArray = [];
      value.forEach((element) => {
        if (returnArray.length == 0) {
          switch (element) {
            case 'MAINCUSTOMER':
              returnArray.push("Hautptkunde");
              break;
            case 'SUBCUSTOMER':
              returnArray.push("Haushaltsmitglied");
              break;
            default:
              returnArray.push(element);
              break;
          }
        }
      });
      return returnArray
    } else if (type == 'filterByState') {
      let returnArray = [];
      value.forEach((element) => {
        if (returnArray.length == 0) {
          switch (element) {
            case 'ACTIVE':
              returnArray.push("Aktiv");
              break;
            case 'PENDING':
              returnArray.push("Wartend");
              break;
            case 'DISABLE_REQUEST':
              returnArray.push("Deaktivierung beantragt");
              break;
            case 'REJECTED':
              returnArray.push("Abgelehnt");
              break;
            case 'DISABLED':
              returnArray.push("Deakiviert");
              break;
            case 'DELETED':
              returnArray.push("Gelöscht");
              break;
            case 'NEW':
              returnArray.push("Neu");
              break;
            default:
              returnArray.push(element);
              break;
          }
        }
      });
      return returnArray
    } else if (type == 'filterBySupplyContractState') {
      let returnArray = [];
      value.forEach((element) => {
        if (returnArray.length == 0) {
          switch (element) {
            case 'RUNNING':
              returnArray.push("Läuft");
              break;
            case 'CANCELLED':
              returnArray.push("Gekündigt");
              break;
            case 'FINISHED':
              returnArray.push("Beendet");
              break;
            default:
              returnArray.push(element);
              break;
          }
        }
      });
      return returnArray
    } else if (type == 'erpState') {
      let returnArray = [];
      value.forEach((element) => {
        if (returnArray.length == 0) {
          switch (element) {
            case 'RUNNING':
              returnArray.push("Läuft");
              break;
            case 'CANCELLED':
              returnArray.push("Gekündigt");
              break;
            case 'FINISHED':
              returnArray.push("Beendet");
              break;
            default:
              returnArray.push(element);
              break;
          }
        }
      });
      return returnArray
    } else if (type == 'filterByCardState') {
      let returnArray = [];
      value.forEach((element) => {
        if (returnArray.length == 0) {
          switch (element) {
            case 'CREATED':
              returnArray.push("Erstellt");
              break;
            case 'AVAILABLE':
              returnArray.push("Verfügbar");
              break;
            case 'REQUESTED':
              returnArray.push("Angefordert");
              break;
            case 'ASSIGNED':
              returnArray.push("Zugeordnet");
              break;
            case 'ACTIVE':
              returnArray.push("Aktiv");
              break;
            case 'CLOSE_REQUEST':
              returnArray.push("Sperrung beantragt");
              break;
            case 'CLOSED':
              returnArray.push("Gesperrt");
              break;
            default:
              returnArray.push(element);
              break;
          }
        }
      });
      return returnArray
    }









  }
}
