import { Component, OnInit } from '@angular/core';
/**
 * The Footer of the Application
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  /**
   * The Current Year
   */
  year: String;
  /**
   * constructor 
   * Set the Year
   */
  constructor() {
    this.year = new Date().getFullYear().toString()
  }
/**
 * ngOnInit
 */
  ngOnInit() {
  }

}
