import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transform the State of the PushMessage based on the Type
 */
@Pipe({
  name: 'translateFilterPush'
})
export class TranslateFilterPushPipe implements PipeTransform {
/**
 * Transform the state based on the Type
 * @param value 
 * @param type 
 * @returns 
 */
  transform(value: string, type: string): string {
    if (type == 'filterSort') {
      switch (value) {
        case 'created,desc':
          return "Erstellungsdatum";
        case 'sendOn,sendingOn,desc':
          return "Senden am";
        default:
          return value;
      }

    }else if (type == 'filterByTypes'){
      switch (value) {
        case 'EVENTS':
          return "Aktion";
        case 'NEWS':
          return "Infobeitrag";
          case 'GARBAGE':
            return "Abfallkalander";
        default:
          return value;
      }
    }
  }

}
