import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transform the State of the Household based on the Type
 */
@Pipe({
  name: 'translateFilterHousehold'
})
export class TranslateFilterHouseholdPipe implements PipeTransform {
/**
 * Transform the state based on the Type
 * @param value 
 * @param type 
 * @returns 
 */
  transform(value: string, type: string): string {
    if (type == 'filterByTypes') {
      switch (value) {
        case '1':
          return ">= 1 Erwachsener im Haushalt";
        case '2':
          return ">= 2 Erwachsener im Haushalt";
        case '3':
          return ">= 3 Erwachsener im Haushalt";
        case '4':
          return ">= 4 Erwachsener im Haushalt";
        case '5':
          return ">= 5 Erwachsener im Haushalt";
        default:
          return value;
      }
    }
  }

}
