import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CardResource } from '../interfaces/card-resouce';
import { PagedResources } from '../interfaces/paged-resources';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  /**
   * constructor
   * @param http 
   * @param environment 
   */
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService) {

  }
  /**
   * Get the active Customercards
   * @param page 
   * @param size 
   * @returns 
   */
  getCards(page, size): Observable<PagedResources<CardResource>> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      "filterByState": ["ACTIVE"]
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post<PagedResources<CardResource>>(`${url}/card/search`, body, { params })
      )
    )
  }
  /**
   * Search for the Cards
   * @param page 
   * @param size 
   * @param filterByState 
   * @param searchTerm 
   * @returns 
   */
  seachCards(page, size, filterByState, searchTerm): Observable<PagedResources<CardResource>> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByState: filterByState,
      searchTerm: searchTerm
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post<PagedResources<CardResource>>(`${url}/card/search`, body, { params })
      )
    )
  }
  /**
   * Get a Card by the ID
   * @param cardID 
   * @returns 
   */
  getCard(cardID: string) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/card/${cardID}`)
      )
    )
  }
  /**
   * Crate a new Card
   * @param card 
   * @returns 
   */
  createCard(card) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/card`, card)
      )
    )
  }
  /**
   * Edit a Card
   * @param card 
   * @returns 
   */
  editCard(card) {

    if(card.state == 'CLOSE_REQUEST'){
      card.ownerPublicId = null
     }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/card/`, card)
      )
    )
  }
  /**
   * Get the QR Code to a Card
   * @param card 
   * @returns 
   */
  getCardQRCode(card) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/card/${card.publicId}/qr`, { responseType: 'blob' })
      )
    )
  }
  /**
   * Delete a Card
   * @param card 
   * @returns 
   */
  deleteCard(card) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.delete(`${url}/card/${card.publicId}`)
      )
    )
  }
  /**
   * Get the History to a Card (when has it been changed / issued)
   * @param cardID 
   * @returns 
   */
  getHistory(cardID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/card/${cardID}/history`)
      )
    )
  }
  /**
   * Get a Card PDF
   * @param cardID 
   * @returns 
   */
  getPDF(cardID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/card/${cardID}/regenerate`, { responseType: 'blob' })
      )
    )
  }
  /**
   * Get a Card Notification PDF
   * @param cardID 
   * @returns 
   */
  getNotiPDF(cardID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/card/${cardID}/notification`, { responseType: 'blob' })
      )
    )
  }

  startCardProcess(type:string,ownerPublicId){
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/card/produce/${ownerPublicId}?processType=${type}`)
      )
    )
  }


  getCardFiles(ownerPublicId){
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/card/files/${ownerPublicId}`)
      )
    )
  } 
}
