import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
/**
 * The Serive to show the Loadingoverlay
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  /**
   * Is the Loadingoverlay visible?
   */
  loading$: Subject<boolean> = new ReplaySubject(1);
  /**
   * Set loading to false
   * constructor
   */
  constructor() {
    this.loading$.next(false);
  }
  /**
   * Show the Loadingoverlay
   */
  public show() {
    this.loading$.next(true);
  }
  /**
   * Hide the Loadingoverlay
   */
  public hide() {
    this.loading$.next(false);
  }
}