import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from 'src/environments/environment';
/**
 * The Header with the Sidenav toggle
 */
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  /**
   * The Sidenav to be opend
   */
  @Input() sidenav: MatSidenav;
  /**
   * The Title of the APP set via ENV
   */
  title: string = environment.appName;
  /**
   * constructor
   * @param authenticationService 
   * @param router 
   */
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) {

  }
  /**
   * ngOnInit
   */
  ngOnInit() {

  }

  /**
   * Logout the user and nav to back to login page
   */
  logout() {
    this.authenticationService.logout();
    this.router.navigateByUrl('/');
  }

}
