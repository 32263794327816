import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/interfaces/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BusinessuserService } from 'src/app/services/businessuser.service';
import { ModulesList } from '../navigation/menu';
import { AdminModulesList } from '../navigation/superadmin-menu';
import { PartnerModulesList } from '../navigation/partner-menu';
import { SBModulesList } from '../navigation/sb-menu';
import { SidenavService } from 'src/app/services/sidenav.service';
import { McpService } from 'src/app/services/mcp.service';
import { SBModulesListDUS } from '../navigation/sb-menu-dus';
import { environment } from 'src/environments/environment';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { UserProperty } from 'src/app/interfaces/user-property';
import { OverlayService } from 'src/app/services/overlay.service';

/**
 * The Options used for sorting
 */
interface SortOption {
  /**
   * The Value of the Filteroption
   */
  value: string;
  /**
   * The viewValue (visible to the User) of the Filteroption
   */
  viewValue: string;
  /**
   * Is the  Filteroption disabled
   */
  state?: boolean;
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})


export class LayoutComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  public user: User;
  events2: string[] = [];
  opened: boolean;
  modulesList: Array<any> = [];
  modulesListAPI: Array<any> = [];
  filterForm: UntypedFormGroup;
  partners = [];
  finishedLoadingToken: boolean = false;
  isSB: boolean = false;
  config;
  public sortOptions: SortOption[] = [
    { value: 'dateAsc', viewValue: 'Datum Absteigend' },
    { value: 'dateDesc', viewValue: 'Datum Aufsteigend' },
  ];

  //TODO
  //Add the detailt state fitting the result to the request without filters(init State) 
  currentApplicationVersion: string = environment.appVersion;

  public sortOptionsState: SortOption[] = [
    { value: 'CREATED', viewValue: 'Erstellt' },
    { value: 'DRAFT', viewValue: 'Entwurf' },
    { value: 'PREVIEW', viewValue: 'Vorschau' },
    { value: 'ACTIVE', viewValue: 'Aktiv' },
    { value: 'HIDDEN', viewValue: 'Versteckt' },
    { value: 'CANCELLED', viewValue: 'Storniert' },
    { value: 'EXPIRED', viewValue: 'Abgelaufen' },
    { value: 'ARCHIVED', viewValue: 'Archiviert' },
    { value: 'DELETED', viewValue: 'Gel�scht' },
  ];

  public sortOptionsDiscountType: SortOption[] = [
    { value: 'MONETARY', viewValue: 'Preisvorteil' },
    { value: 'SERVICE_PLUS', viewValue: 'Exklusiv' },
    { value: 'EXTERNAL_EVENT', viewValue: 'Partneraktion' },
    { value: 'RAFFLE', viewValue: 'Gewinnspiel' }
  ]

  constructor(
    public fb: UntypedFormBuilder,
    public router: Router,
    private auth: AuthenticationService,
    private businessuserService: BusinessuserService,
    private authenticationService: AuthenticationService,
    private sidenavService: SidenavService,
    private overlayService: OverlayService,
    private mcp: McpService,) {

    this.filterForm = this.fb.group({
      filterState: [[]],
      filterDiscountType: [[]],
      filterByPartners: [[]],
    })


  }
  logout = [
    {
      name: 'Abmelden',
      icon: 'arrow_back',
      path: ''
    },
  ];


  ngOnInit() {

    if (this.authenticationService.isAdmin()) {
      this.modulesList = ModulesList;
      this.auth.decodedToken$.subscribe(token => {
        this.businessuserService.getBusinessUserForMenu(token.sub).subscribe((user: User) => {
          this.finishedLoadingToken = true;
          this.user = user;
        })
      })
    } else if (this.authenticationService.isSuperAdmin()) {
      this.modulesList = AdminModulesList;
      this.auth.decodedToken$.subscribe(token => {
        this.businessuserService.getBusinessUserForMenu(token.sub).subscribe((user: User) => {
          this.finishedLoadingToken = true;
          this.user = user;
        })
      })

    } else if (this.authenticationService.isSB()) {
      this.isSB = true;
      this.mcp.loadConfiguration().subscribe({
        next: (config: any) => {
          if (config.SB_MENU != undefined) {
            this.modulesList = config.SB_MENU;
          } else {
            if (config?.can_only_see_reporting == true) {
              this.modulesList = SBModulesListDUS;
            } else {
              this.modulesList = SBModulesList;
            }
          }
        },
        error: err => {
          console.log(err)
        }
      })

      this.auth.decodedToken$.subscribe(token => {
        if(token){
          this.businessuserService.getBusinessUserForMenu(token.sub).subscribe((user: User) => {
            this.finishedLoadingToken = true;
            this.user = user;
            this.businessuserService.getUserProperties(user.publicId).subscribe({
              next: (userProperties: UserProperty[]) => {
                //User has to set a new PW
                if (userProperties.filter(userProperty => userProperty.name == "isCredentialsNonExpired" && !JSON.parse(userProperty.value)).length > 0) {
                  this.overlayService.reopenAccount("Passwort setzen", 'Passwort setzen', user);
                }
              }
            })
          })
        }

      })
    } else if (this.authenticationService.isPartner()) {
      this.modulesList = PartnerModulesList;
      this.auth.decodedToken$.subscribe(token => {
        this.businessuserService.getBusinessUserForMenu(token.sub).subscribe((user: User) => {
          this.finishedLoadingToken = true;
          this.user = user;
        })
      })
    }
  }

  logoutUser() {
    this.auth.logout();
    this.router.navigateByUrl('/');
  }


  /**
   * scroll to top if the page is not
   */
  onActivate(event) {
    //  document.getElementsByTagName('mat-sidenav-content')[0].scrollTop = 0;
  }


  onMenuOpen() {
    document.getElementsByClassName('is-active')[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
