import { Component, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  /**
   * is the Page Loading
   */
  showLoading: boolean = true;
  /**
   * constructor
   * @param router 
   * @param loadingService 
   */
  constructor(
    private router: Router,
    private loadingService: LoadingService) {
  }
  /**
   * ngOnInit
  * Show and Hide the loading Animation on Navigationvents
  */
  ngOnInit() {
    this.loadingService.loading$.subscribe(loading => {
      this.showLoading = loading;
    })


    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loadingService.show();
          break;
        } case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loadingService.hide();
          break;
        }
        default: {
          break;
        }
      }
    });
  }
}
